<!--
this component is used in Pending Policy
-->
<template>
    <q-submenu :title="title" :titlelink="[]" :baseUrl="''" :buttons="[]" :submenu="submenu" class="q-stycky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    props: ["hasDemo"],
    data() {
        return {
            title: "Pending Policy",

        }
    },
    computed: {
        submenu: function() {
            var g = this;
            var submenu = [{
                label: "Help",
                to: "/support"
            }, ];
            if (this.hasDemo) {
                submenu.push({
                    label: "Start Demo",
                    fun: function() {
                        g.$emit("demo");
                    }
                })
            }
            return submenu;
        },

    },
    components: {
        QSubmenu
    },
    methods: {},
}

</script>
<style>
</style>
