<template>
    <div>
        <q-my-clients-submenu></q-my-clients-submenu>
        <v-row class="mx-5">
            <v-col cols=12>
                <q-applications-table title="Applications"></q-applications-table>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import QApplicationsTable from '@/components/datatables/QApplicationsTable.vue'
import QMyClientsSubmenu from "@/components/navigation/Submenus/QMyClientsSubmenu";

export default {
    name: "Applications",
    data() {
        return {

        };
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {},
    components: {
        QApplicationsTable,
        QMyClientsSubmenu
    }
};

</script>
