<template>
    <q-submenu :base-url="baseUrl" :title="title" :titlelink="titlelink" :buttons="buttons" class="q-sticky"></q-submenu>
</template>
<div>works</div>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
export default {
    data() {
        return {
            title: "New Business",
            titlelink: null,
            baseUrl: '/new-business',
            buttons: [{
                label: "New Business",
                anchor: "#News",
                icon: "fas fa-arrow-alt-circle-down",
            }, ],
        }
    },
    computed: {},
    components: {
        QSubmenu
    },
    methods: {},
}

</script>
<style>
</style>
