<template>
    <div>
        <q-my-clients-submenu></q-my-clients-submenu>
        <QTabs class="q-text-left lighest_grey" background-color="q_symm_blue" dark>
            <v-tabs-slider color="q_leads_1"></v-tabs-slider>
            <v-tab id="q-pending-policy-step-1">
                My Pending Policies
                <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
            </v-tab>
            <v-tab id="q-pending-policy-step-2" v-if="hasRole('AgencyOwner')">
                Base Shop Pending Policies
                <v-icon right>fas fa-arrow-alt-circle-right</v-icon>
            </v-tab>
            <v-tab-item class="lighest_grey">
                <v-row class="mx-5">
                    <v-col cols=12>
                        <v-card class="pb-2">
                            <q-pending-policy-data-table :agent="user.Agent" :rows="25" title="Pending Policies"></q-pending-policy-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item class="lighest_grey" v-if="hasRole('AgencyOwner')">
                <v-row class="mx-5">
                    <v-col cols=12>
                        <v-card class="pb-2">
                            <q-pending-policy-data-table :for_baseshop="1" :agent="user.Agent" :rows="25" title="Baseshop Pending Policies"></q-pending-policy-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
        </QTabs>
    </div>
</template>
<script>
//table data + search
import QPendingPolicyDataTable from '@/components/datatables/QPendingPolicyDataTable.vue';
import QMyClientsSubmenu from '@/components/navigation/Submenus/QMyClientsSubmenu.vue';
import QTabs from '../../../components/navigation/QTabs.vue'


export default {
    data() {
        return {
            search: '',
        }
    },
    mounted: function() {},
    computed: {},
    methods: {
        'startDemo': function() {
            this.$tours['DemoPendingPolicy'].start()
        },
    },


    components: {
        QPendingPolicyDataTable,
        QMyClientsSubmenu,
        QPendingPolicyDataTable,
        QTabs
    },
}
</script>