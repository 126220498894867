<template>
    <div>
        <v-card class="mt-2">
            <v-card-title class="q_leads_1 align-start" style="font-size: 1rem" :id=" entity + '_datatable'">
                <v-col cols="12" sm="8">
                    <div class="white--text d-flex align-center">
                        <p class="q-display-3 mb-0" id="datatable">
                            {{ title }}
                        </p>
                        <v-btn dark icon @click="refresh">
                            <v-icon>fas fa-redo-alt</v-icon>
                        </v-btn>
                    </div>
                    <p class="pt-2 mb-0">
                        <v-btn class="pa-2" text small color="q_leads_4" @click="toggleFilters">
                            <v-icon small left>fas fa-filter</v-icon> Filter Applications
                        </v-btn>
                    </p>
                </v-col>
                <v-col cols="12" sm="4">
                    <!-- Searchbar with issues -->
                    <!-- <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details @keyup="maybeSearch" @click:append="refreshData" /> -->
                    <q-time-period-select :dark="true" v-model="filters" label="Time Period" :allow-current="true" stateKey="stat"></q-time-period-select>
                </v-col>
            </v-card-title>
            <v-card-text v-show="$refs.filters && $refs.filters.show">
                <v-row class="d-flex justify-start">
                    <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
                </v-row>
            </v-card-text>
            <v-row class="mx-4 my-2">
                <v-col cols="12" class="d-flex justify-end" v-if="total_items > 0 && !$vuetify.breakpoint.smAndDown">
                    <v-btn text small color="q_leads_4" @click="exportResults">
                        <v-icon small left>fas fa-file-export</v-icon> Export Results
                    </v-btn>
                </v-col>
            </v-row>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0 pointer" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }" :loading="loading" :options.sync="options" :server-items-length="total_items" v-on:click:row="viewApplication">
                <template v-slot:item.CreateDate="{ item }">
                    {{ formatDate(item.CreateDate) }}
                </template>
                <template v-slot:item.AgentCode="{ item }">
                    <span class="nowrap"><span>{{ item.AgentCode }}</span></span>
                </template>
                <template v-slot:item.FirstName="{ item }">
                    <span class="nowrap"> {{ item.FirstName }} {{ item.LastName }} </span>
                </template>
                <template v-slot:item.SplitPercent="{ item }">
                    <v-icon small v-if="item.SplitPercent > 0">fas fa-check</v-icon>
                </template>
                <template v-slot:item.APV="{ item }">
                    <span class="nowrap"><span>{{ formatCurrency(item.APV) }}</span></span>
                </template>
                <template v-slot:body.append>
                    <tr>
                        <th class="title">Totals</th>
                        <th colspan="5"></th>
                        <th class="title">{{ sumField("APV") }}</th>
                        <!-- <td colspan="10">
                            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
                        </td> -->
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="view_application_details" width="500px" max-width="90%" persistent>
            <v-card class="pa-6" min-height="" v-if="current_application">
                <v-progress-linear color="q_leads_1" indeterminate v-if="saving"></v-progress-linear>
                <v-row wrap>
                    <v-col cols="12">
                        <h2>{{ current_application.FirstName }} {{ current_application.LastName }}</h2>
                        <div class="mb-3" v-for="item in application_details_data" :key="item.title">
                            <span v-if="current_application[item.title]">
                                <strong>{{ item.title }}</strong>: {{ current_application[item.title] }}<br />
                            </span>
                        </div>
                        <v-progress-linear color="q_leads_1" indeterminate v-if="status_items == null"></v-progress-linear>
                        <div v-else>
                            <v-row class="d-flex px-5">
                                <v-spacer></v-spacer>
                                <v-btn depressed @click=" view_application_details = false; saving = false;">Close</v-btn>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin";
import Application from "@/store/Models/Application";
import QuilityAPI from "@/store/API/QuilityAPI.js";
import { debounce } from "vue-debounce";
import StateOptions from "@/store/StateOptions";
import QDataTableHeader from "./base/QDataTableHeader.vue";
import QTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue';
import QScoreCardPieChart from '@/components/stats/scorecards/QScoreCardPieChart.vue'
import moment from 'moment';
import { numberFormat } from 'highcharts';

export default {
    name: "QApplicationsTable",
    mixins: [QDataTableMixin],
    props: ["agent"],
    data: function() {
        // data internal to component (not available outside)
        return {
            entity: "application",
            view_application_details: false,
            //use this to "preset" a filter
            filters: {},
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
            applications: [],
            current_application: null,
            options: { sortBy: ["ApplicationDate"], sortDesc: ["true"] },
            status_items: [],
            saving: false,
            is_exporting: false,
            states: StateOptions, //for the state drop down list
            warning: null,
            error: null,
            orig_application_status: null,
            application_details_data: [
                {
                    title: 'Type',
                },
                {
                    title: "Source",
                },
                {
                    title: "Product",
                },
                {
                    title: "ProductType",
                },
                {
                    title: "Carrier",
                },
                {
                    title: "FaceAmount",
                },
                {
                    title: "APV",
                },
                {
                    title: "SplitPercent",
                }
            ],
            datePeriod: { label: 'Current Month', 'period': 'month' },
        };
    },
    mounted: function() {
        this.loadStatuses();
    },
    computed: {
        filter_keys: function() {
            return Application.state().filter_keys;
        },
        all_data: function() {
            return this.applications;
        },
        the_headers() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return [
                    {
                        text: "Date Created",
                        value: "CreateDate",
                        align: "start",
                        sortable: true,
                        filterable: true,
                    },
                    {
                        text: "Name",
                        value: "FirstName",
                        align: "start",
                        sortable: true,
                        filterable: true,
                        class: "header-class",
                    },
                    {
                        text: "Type",
                        value: "Type",
                        sortable: true,
                        filterable: true,
                    },
                    {
                        text: "APV",
                        value: "APV",
                        sortable: true,
                        filterable: true,
                    },
                    {
                        text: "ProductType",
                        value: "ProductType",
                        sortable: true,
                        filterable: true,
                    },
                ];
            }

            if (this.$vuetify.breakpoint.md) {
                return [
                    {
                        text: "Date Created",
                        value: "CreateDate",
                        align: "start",
                        sortable: true,
                        filterable: true,
                        class: "header-class",
                    },
                    {
                        text: "Name",
                        value: "FirstName",
                        align: "start",
                        sortable: true,
                        filterable: true,
                        class: "header-class",
                    },
                    {
                        text: "Type",
                        value: "Type",
                        sortable: true,
                        filterable: true,
                    },
                    {
                        text: "ProductType",
                        value: "ProductType",
                        sortable: true,
                        filterable: true,
                    },
                    {
                        text: "Carrier",
                        value: "Carrier",
                        sortable: true,
                        filterable: true,
                    },
                    {
                        text: "APV",
                        value: "APV",
                        sortable: true,
                        filterable: true,
                    },
                    {
                        text: "Split",
                        value: "SplitPercent",
                        sortable: true,
                        filterable: true,
                    },
                ];
            }

            const r = [
                {
                    text: "Date Created",
                    value: "CreateDate",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    class: "header-class",
                },
                {
                    text: "Name",
                    value: "FirstName",
                    align: "start",
                    sortable: true,
                    filterable: true,
                    class: "header-class",
                },
                {
                    text: "Type",
                    value: "Type",
                    sortable: true,
                    filterable: true,
                },
                {
                    text: "Source",
                    value: "Source",
                    sortable: true,
                    filterable: true,
                },
                {
                    text: "ProductType",
                    value: "ProductType",
                    sortable: true,
                    filterable: true,
                },
                {
                    text: "Carrier",
                    value: "Carrier",
                    sortable: true,
                    filterable: true,
                },
                {
                    text: "APV",
                    value: "APV",
                    sortable: true,
                    filterable: true,
                },
                {
                    text: "Split",
                    value: "SplitPercent",
                    sortable: true,
                    filterable: true,
                },
            ];
            return r;
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            let g = this;
            if (g.is_exporting) {
                opt.itemsPerPage = "csv";
            }
            // console.info('AgentCode: ',g.user.Agent.AgentCode);
            QuilityAPI.getApplications(g.user.Agent.AgentCode, g.filters, opt)
                .then(function(results) {
                    if (g.is_exporting) {
                        let fileURL = window.URL.createObjectURL(new Blob([results]));
                        let fileLink = document.createElement("a");
                        fileLink.href = fileURL;
                        fileLink.setAttribute("download", g.entity + ".csv");
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    } else {
                        if (typeof results.data != "undefined") {
                            g.applications = results.data;
                        } else {
                            g.showError("Whoops! " + results.message);
                        }
                    }
                    g.is_exporting = false;
                    Application.commit((state) => {
                        state.loading = false;
                    });
                })
                .catch(function(err) {
                    console.log(err);
                    if (err.status == 431) {
                        g.showError(err + " You probably need to clear all your cookie and session variables and login again.");
                        return;
                    }
                    //g.showError(err.status)
                });
            g.firstPull = false;
        }, 200),
        viewApplication: function(application) {
            this.orig_application_status = application.Status;
            this.current_application = application;
            this.view_application_details = true;
        },
        loadStatuses: function() {
            var g = this;
            QuilityAPI.getStatusOptions()
                .then(function(response) {
                    g.status_items = response;
                })
                .catch(function(err) {});
        },
        refresh: function() {
            let g = this;
            g.applications = [];
            Application.commit((state) => {
                state.total = null;
                state.availableFilters = null;
            });
            g.refreshData();
        },
        exportResults: function() {
            let g = this;
            let opt = {
                ...g.options,
                search: g.search,
            };
            let endpoint = "/api/private/applications/" + g.user.Agent.AgentCode;
            let params = {
                ...opt,
                ...g.filters,
                itemsPerPage: "csv",
            };
            g.dispatchJob({
                method: "GET",
                path: endpoint,
                params: params,
                JobName: "Applications Export",
            });

            this.is_exporting = true
            this.refreshData();
        },
        status_itemsHasIndexOf: function(s) {
            for (var i = 0; i < this.status_items.length; i++) {
                if (this.status_items[i].Status == s) {
                    return true;
                }
            }
            return false;
        },
        getStatus: function(s) {
            for (var i = 0; i < this.status_items.length; i++) {
                if (this.status_items[i].Status == s) {
                    return this.status_items[i];
                }
            }
            return null;
        },
        updateFilters(filters) {
            Object.keys(this.filters).forEach((key) => {
                this.filters[key] = filters[key] || "-All-";
            });

            this.goToTop();
        },
        goToTop: function() {
            this.$vuetify.goTo("#" + this.entity + "_datatable", {
                duration: 500,
                easing: "easeOutCubic",
                offset: 300,
            });
        },
        formatDate(value) {
            return moment(value).format("YYYY-MM-DD")
        },
        formatCurrency (value) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
        },
        sumField(key) {
            let totals = this.applications.reduce((a, b) => a + Number((b[key] || 0)), 0)
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totals)
        }
    },
    watch: {
        view_application_details: function() {
            this.current_application.Status = this.orig_application_status;
            this.logActivity("ViewApplication", { AgentCode: this.user.Agent.AgentCode });
        },
    },
    components: {
        QDataTableHeader,
        QTimePeriodSelect,
        QScoreCardPieChart
    },
};
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.table-cursor tbody tr:hover {
    cursor: pointer;
}
</style>